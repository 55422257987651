<template>
  <v-app>
    <v-container class="my-6 mx-auto" style="max-width: 1500px">
      <v-card>
        <v-row class="px-4 pt-4">
          <v-col lg="6">
            <div class="mb-10">
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                {{ title }}
                <span v-if="filteredLessonCategories && lessonCategories"
                  >({{
                    lessonCategories.length - filteredLessonCategories.length
                  }})</span
                >
              </h5>
              <p class="text-sm text-body mb-0">
                {{ description }}
              </p>
            </div>
          </v-col>
          <v-col lg="6" class="text-end my-auto">
            <v-btn
              @click="_addNewItem"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                border-radius-sm
                text-xxs text-white
                me-1
                shadow-none
                font-weight-bold
                px-3
                py-1
                text-uppercase
                btn-danger
                bg-gradient-danger
              "
              >{{ addButtonTitle }}+</v-btn
            >
          </v-col>
        </v-row>

        <v-card-title>
          <v-row>
            <v-col cols="6">
              <v-radio-group v-model="mustorselection">
                <v-radio
                  :value="rt.value"
                  v-for="(rt, rtindex) in $attrs.optionValues"
                  :key="'optionValues' + rtindex"
                >
                  <template v-slot:label>
                    <div>
                      {{ rt.name }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Xайx"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

        <v-data-table
          v-model="selected"
          :headers="headers2"
          :items="filteredLessonCategories"
          :search="search"
          hide-default-footer
          :items-per-page="-1"
        >
          <template slot="item" slot-scope="props">
            <tr @click="showAlert(props.item)">
              <td>{{ props.item.index }}</td>
              <td v-for="(name, index) in bodyNames" :key="'td' + index">
                {{ props.item[name] }}
              </td>
              <td>
                <small
                  >{{ props.item.lessonTypeName }} ({{
                    props.item.lessonType
                  }})</small
                >
              </td>
              <td>
                {{ formatXDate(props.item.createdAt) }}
              </td>
              <td v-if="directGo">
                <v-icon
                  small
                  class="mr-2"
                  @click.stop="_deleteItem(props.item)"
                >
                  mdi-delete
                </v-icon>
                <v-icon small class="mr-2" @click.stop="_editItem(props.item)">
                  mdi-pencil
                </v-icon>
                <!-- <v-icon small class="mr-2" @click.stop="_detail(props.item)">
                  mdi-eye
                </v-icon> -->
              </td>
            </tr>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card class="card-shadow card-padding border-radius-xl">
            <v-card-title class="pt-0 text-h5 text-typo justify-center"
              >Та устгаxыг xүсэж байна уу?</v-card-title
            >
            <v-card-actions class="pb-0">
              <v-spacer></v-spacer>
              <v-btn
                @click="closeDelete"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls
                  bg-gradient-light
                  py-3
                  px-6
                "
                >Үгүй</v-btn
              >

              <v-btn
                @click="_deleteItemConfirm"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-primary
                  bg-gradient-primary
                  py-3
                  px-6
                "
                >Тийм</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>

    <v-dialog v-model="newDialog" max-width="500px">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0"
            >{{ addButtonTitle }} нэмэx/засаx</span
          >
        </div>
        <v-card-text class="card-padding">
          <v-container class="px-0">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  autofocus
                  v-model.trim="editedItem.name"
                  placeholder="Name"
                  ref="changeNameInputField"
                ></v-text-field>

                <v-text-field
                  v-model.trim="editedItem.phone"
                  placeholder="Xолбоо бариx утасны дугаар"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-radio-group v-model="mustorselection">
                  <v-radio
                    :value="rt.value"
                    v-for="(rt, rtindex) in $attrs.optionValues"
                    :key="'optionValues' + rtindex"
                  >
                    <template v-slot:label>
                      <div>{{ rt.name }}</div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  v-model="editedItem.description"
                  hide-details
                  outlined
                  label="Тайлбар"
                  color="rgba(0,0,0,.6)"
                  class="
                    font-size-input
                    border
                    text-light-input
                    border-radius-md
                    mt-2
                  "
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="_close"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-secondary
              bg-gradient-light
              py-3
              px-6
            "
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_save"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-gradient-primary
              py-3
              px-6
            "
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import myfunction from "@/components/global/myfunction.js";
const fb = require("@/firebaseConfig.js");
export default {
  components: {},
  mixins: [myfunction],
  data: () => ({
    mustorselection: 1,

    departments: null,
    selected: [],
    dialogDelete: false,
    newDialog: false,
    search: "",
    lessonCategories: null,
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    selectedLessonCategory: null,
  }),

  props: {
    title: String,
    description: String,
    addButtonTitle: String,
    childPath: String,

    path: {
      type: String,
      required: true,
    },
    directGo: {
      type: Boolean,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    goPparent: {
      type: Boolean,
      default: false,
    },
    goChild: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
      required: true,
    },
    headerNames: {
      type: Array,
      required: true,
    },
    bodyNames: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    headers2() {
      return this.headerNames;
    },

    filteredLessonCategories() {
      var list = [];
      if (this.lessonCategories != null) {
        var counter = 0;
        for (var item of this.lessonCategories) {
          counter++;
          item.index = counter;
          //if (item.lessonType == this.mustorselection)
          list.push(item);
        }
      }
      return list;
    },
  },
  created() {
    this.query.onSnapshot((docs) => {
      this.lessonCategories = [];
      docs.forEach((doc) => {
        let item = doc.data();
        item.id = doc.id;
        item.ref = doc.ref;
        this.lessonCategories.push(item);
      });
    });
  },
  watch: {},
  methods: {
    _getLessonTypeName(lessonType) {
      return this.$attrs.optionValues.find(
        (element) => element.value == lessonType
      );
    },
    _addNewItem() {
      //this.$refs.changeNameInputField.focus();
      this.newDialog = true;
    },
    _saveLessonCategories() {
      var counter = 0;
      for (var lessonCategory of this.lessonCategories) {
        var newLessonData = {
          name: lessonCategory.name,
          lessonType: lessonCategory.lessonType,
          lessonTypeName: lessonCategory.lessonTypeName,
          createdAt: new Date(),
        };
        fb.db
          .collection(this.lessonCategoriesPath)
          .doc(lessonCategory.ref.id)
          .set(newLessonData, { merge: true })
          .then(() => {
            console.log(++counter);
          });
      }
    },
    showAlert(a) {
      if (this.directGo == false) this._detail(a);
    },
    _detail(item) {
      this.$router.push({
        name: "UserDetail",
        params: {
          userrefid: item.ref.path,
        },
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _deleteItemConfirm() {
      this.editedItem.ref.delete().then(() => {
        this.closeDelete();
      });
    },
    _deleteItem(item) {
      this.editedIndex = this.lessonCategories.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    _editItem(item, index) {
      this.newDialog = true;
      this.editedIndex = index;
      this.editedItem = Object.assign({}, item);
    },
    _close() {
      this.newDialog = false;
      this.messageNotification = null;

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _save() {
      if (this.editedItem.name && this.editedItem.name.trim() !== "") {
        var x = {};
        x.name = this.editedItem.name;
        if (this.editedItem.description)
          x.description = this.editedItem.description;
        else x.description = null;
        x.createdAt = new Date();
        x.createdBy = this.userData.ref;

        var xx = this._getLessonTypeName(this.mustorselection);
        if (xx) {
          x.lessonType = xx.value;
          x.lessonTypeName = xx.name;
        } else {
          x.lessonType = null;
          x.lessonTypeName = null;
        }

        if (this.editedIndex === -1) {
          fb.db
            .collection(this.path)
            .doc()
            .set(x)
            .then(() => {
              this._close();
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        } else {
          this.editedItem.ref.update(x).then(() => {
            this._close();
          });
        }
      }
    },
  },
};
</script>
 